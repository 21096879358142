import styled from '@emotion/styled'
import { TableCell } from '@mui/material'
import type { SupportedPaymentUpdate } from '@orus.eu/backend/src/events/payment-update'
import type { StripeCustomerCreation } from '@orus.eu/backend/src/events/stripe-customer-creation'
import type { VerifiedUserAccount } from '@orus.eu/backend/src/views/user-account-view'
import type { UserActivity, UserActivityData, UserTimelineItem } from '@orus.eu/backend/src/views/user-activity-view'
import { ensureError } from '@orus.eu/error'
import {
  Avatar,
  Button,
  ButtonLink,
  ContentContainerBackoffice,
  FlexRow,
  PersistentNotification,
  Text,
  mobileMediaQuery,
  spacing,
  useCrash,
} from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { useParams, useRouter } from '@tanstack/react-router'
import { memo, useEffect, useState } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { getAdminInvoiceDownloadLink, getAdminReceiptDownloadLink } from '../../../../../lib/download-links'
import { assert } from '../../../../../lib/errors'
import { formatDateTime, formatDateTimeSeconds, getUserLabel } from '../../../../../lib/format'
import { useNavigateTo } from '../../../../../lib/hooks/use-navigate-to-route'
import { getPaymentMethodDisplayName } from '../../../../../lib/payment-method-util'
import {
  getGocardlessCustomerUrl,
  getGocardlessPaymentIntentUrl,
  getStripeCustomerUrl,
  getStripePaymentIntentUrl,
} from '../../../../../lib/payment-provider-util'
import { usePermissions } from '../../../../../lib/use-permissions'
import { BackofficeSubsectionTitle } from '../../../../atoms/backoffice-subsection-title'
import { BackofficeDataCard, DataList, DataRow } from '../../../../molecules/backoffice-data-card'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { SubscriptionActivityProducts } from '../../../../molecules/subscription-activity-products'
import { SubscriptionPriceRecurrence } from '../../../../molecules/subscription-price-recurrence'
import { SubscriptionStatusChip } from '../../../../molecules/subscription-status-chip'
import { TableCellHeader } from '../../../../molecules/table-elements/table-cell-header'
import { TableRowLink } from '../../../../molecules/table-elements/table-row-link'
import { BackofficeContractCard } from '../../../../organisms/backoffice-contract-card'
import { BackofficeCustomerCard } from '../../../../organisms/backoffice-customer-card'
import { BackofficeTable } from '../../../../organisms/backoffice-table'
import { ChipOrganizations } from '../../../../organisms/chip-organisations'
import { GenericProblemMessage } from '../../../../organisms/generic-problem-message'
import { ContractsClaims } from '../../../report-claim/ReportClaimPage'
import type { BackofficeUsage } from '../backoffice-contract-page/contract-page-content'

type UserPageContentProps = {
  type: BackofficeUsage
  user: UserActivity | UserActivityData | undefined
  customerId: string | undefined
  createSubscription: () => void
  activityOriginTimestamp: number | undefined
  onLoadAllHistory: () => void
}

const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mobileMediaQuery} {
    margin: 0 ${spacing[70]} 0 ${spacing[70]};
  }
`

const ButtonRow = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[20]};
`

export const UserPageContent = memo<UserPageContentProps>(function UserPageContent({
  type,
  user: user,
  customerId,
  createSubscription,
  activityOriginTimestamp,
  onLoadAllHistory,
}) {
  const { history } = useRouter()
  const { permissions } = usePermissions()
  const { createdByOperator, isLoading } = useOperatorCreatingUser(user?.contracts[0]?.subscriptionId)
  const navigateToBackofficeUsers = useNavigateTo({ to: '/bak/users' })

  if (user === undefined || customerId === undefined)
    return (
      <GenericProblemMessage
        title="Utilisateur introuvable"
        principalMessage="L'utilisateur est introuvable"
        firstSubText="L'adresse de la page est probablement erronée"
        secondSubText="Revenez en arrière et réessayez"
        onButtonClick={() => {
          history.go(-1)
        }}
        buttonText="Revenir à la page précédente"
      />
    )

  const [organizationsTechnicalNames] =
    trpcReact.organizations.getOrganizationsTechnicalNamesBySubscriptionIds.useSuspenseQuery(
      user.contracts.map((contract) => contract.subscriptionId),
    )

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header
        title={getUserLabel(user.userAccount)}
        leftButton={
          <Button icon="arrow-left-regular" size="small" variant="secondary" onClick={navigateToBackofficeUsers} />
        }
      />

      {user.customer ? (
        <>
          <BackofficeCustomerCard customer={user.customer} />
          <BackofficeDataCard title="Apporteur d’affaires" icon="briefcase-regular">
            <DataList>
              <DataRow>
                <Text variant="body2">Courtier</Text>
                {organizationsTechnicalNames.length > 0 ? (
                  <div>
                    <ChipOrganizations organizations={organizationsTechnicalNames} />
                  </div>
                ) : undefined}
              </DataRow>
              <DataRow>
                <Text variant="body2">Responsable du contrat</Text>
                <Text variant="body2Medium">
                  {!isLoading && !createdByOperator ? 'N/A' : !createdByOperator ? ' ' : createdByOperator.email}
                </Text>
              </DataRow>
            </DataList>
          </BackofficeDataCard>

          <ContractHeader>
            <Text variant="body1Medium">Contrats</Text>

            {permissions.includes('subscription.create') ? (
              <Button variant="primary" onClick={createSubscription} size="small">
                <ButtonRow>
                  <Avatar size="20" icon="plus-solid" />
                  Nouveau devis
                </ButtonRow>
              </Button>
            ) : null}
          </ContractHeader>

          {user.contracts.map((contract, index) =>
            user.customer ? (
              <BackofficeContractCard
                key={index}
                isSummary
                showContractLink
                contract={contract}
                customerEmail={user.customer?.email ?? ''}
                type={type}
              />
            ) : (
              <></>
            ),
          )}
        </>
      ) : (
        <PersistentNotification variant="info">This user is a backoffice agent</PersistentNotification>
      )}

      <CustomerSubscriptionsList customerId={customerId} userActivity={user} type={type} />

      {'timeline' in user ? (
        <>
          <ContractHeader>
            <Text variant="body1Medium">Timeline</Text>
          </ContractHeader>

          <BackofficeDataCard title="Timeline" icon="list-ul-regular">
            <DataList>
              {user.timeline.sort(compareTimelineItems).map((timelineItem, index) => (
                <DataRow key={index}>
                  <TimelineItem key={index} timelineItem={timelineItem} />
                </DataRow>
              ))}
            </DataList>
          </BackofficeDataCard>

          {activityOriginTimestamp ? (
            <Button variant="text" onClick={() => onLoadAllHistory()} size="small">
              Charger l’historique complet
            </Button>
          ) : null}
        </>
      ) : undefined}

      <BackofficeSubsectionTitle>Coordonnées sinistres données au client</BackofficeSubsectionTitle>
      <UserContractsClaims userId={user.id} />
    </ContentContainerBackoffice>
  )
})

function useOperatorCreatingUser(subscriptionId: string | undefined): OperatorCreatingUserHookState {
  const crash = useCrash()

  const [state, setState] = useState<OperatorCreatingUserHookState>({
    createdByOperator: undefined,
    isLoading: true,
  })
  useEffect(() => {
    if (!subscriptionId) {
      setState({ createdByOperator: undefined, isLoading: false })
      return
    }

    let cancelled = false

    trpc.contracts.getContractCreatedByOperator.query(subscriptionId).then(
      (createdByOperator) => {
        if (cancelled) return
        setState({ createdByOperator: createdByOperator ?? undefined, isLoading: false })
      },
      (err: unknown) => {
        crash(ensureError(err))
      },
    )

    return () => {
      cancelled = true
    }
  }, [subscriptionId, crash])

  return state
}

type OperatorCreatingUserHookState = {
  createdByOperator: VerifiedUserAccount | undefined
  isLoading: boolean
}

const UserContractsClaims = memo<{ userId: string }>(function UserContractsClaims({ userId }) {
  const [contractsClaimsContactInformations] =
    trpcReact.claims.getClaimsContactInfoAsPartnerOrPlatform.useSuspenseQuery({ userId })
  return <ContractsClaims contractsClaimsContactInformations={contractsClaimsContactInformations} />
})

const CustomerSubscriptionsList = memo<{
  customerId: string
  userActivity: UserActivityData
  type: BackofficeUsage
}>(function CustomerSubscriptionsList({ customerId, userActivity, type }) {
  const { organization } = useParams({ strict: false })
  const { data: subscriptionsForPlatform, isLoading: isLoadingPlatform } =
    trpcReact.subscriptions.listUserUnsignedSubscriptionsForPlatform.useQuery(
      {
        userId: customerId,
      },
      { enabled: type === 'platform' },
    )
  const { data: subscriptionsForPartners, isLoading: isLoadingPartners } =
    trpcReact.subscriptions.listUserUnsignedSubscriptionsForPartners.useQuery(
      {
        userId: customerId,
        organization: organization!,
      },
      { enabled: type === 'partner' && !!organization },
    )
  const [organizationTechnicalName] =
    userActivity.contracts.length > 0
      ? trpcReact.organizations.getOrganizationTechnicalNameBySubscriptionId.useSuspenseQuery(
          userActivity.contracts[0].subscriptionId,
        )
      : [undefined]

  if (type === 'partner') assert(!!organization, 'If current user is partner, then an organization shall be given')

  if ((type === 'platform' && isLoadingPlatform) || (type === 'partner' && isLoadingPartners))
    return <GlobalLoadingState />

  // This array only contains either the subscriptions from the platform or the partners
  // never both of them, based on the enabled prop of the queries above that enforce the
  // alternative
  const subscriptions = [...(subscriptionsForPlatform ?? []), ...(subscriptionsForPartners ?? [])]

  return (
    <>
      <ContractHeader>
        <Text variant="body1Medium">Devis</Text>
      </ContractHeader>

      {subscriptions.length === 0 ? (
        <Text variant="body2">Aucune souscription trouvée pour cet utilisateur</Text>
      ) : (
        <BackofficeTable
          headers={
            <>
              <TableCellHeader>Date de création</TableCellHeader>
              <TableCellHeader>Activité</TableCellHeader>
              <TableCellHeader>Prix</TableCellHeader>
              <TableCellHeader>Statut</TableCellHeader>
            </>
          }
          rows={subscriptions.map((subscription) => (
            <TableRowLink
              key={subscription.id}
              href={
                type === 'platform'
                  ? { to: '/bak/v2-pending-subscriptions/$subscriptionId', params: { subscriptionId: subscription.id } }
                  : {
                      to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
                      params: {
                        organization: organizationTechnicalName,
                        subscriptionId: subscription.id,
                      },
                    }
              }
            >
              <TableCell>
                <Text variant="body2">{formatDateTime(subscription.creationTimestamp)}</Text>
              </TableCell>
              <TableCell>
                <SubscriptionActivityProducts
                  activableProductInformationIds={subscription.activableProductInformationIds}
                  activityName={subscription.activityName}
                />
              </TableCell>
              <TableCell>
                {subscription.quote ? <SubscriptionPriceRecurrence quote={subscription.quote} /> : null}
              </TableCell>
              <TableCell>
                <SubscriptionStatusChip status={subscription.status} />
              </TableCell>
            </TableRowLink>
          ))}
        />
      )}
    </>
  )
})

function compareTimelineItems(a: UserTimelineItem, b: UserTimelineItem): number {
  return b.timestamp - a.timestamp
}

type TimelineItemProps = {
  timelineItem: UserTimelineItem
}

function getItemLabel(item: UserTimelineItem): string {
  switch (item.type) {
    case 'InvoiceEmission':
      return 'Invoice emission'
    case 'PaymentMethodCreation':
      return 'Payment method added'
    case 'PaymentUpdate':
      return 'Payment status update'
    case 'PaymentProviderCustomerCreation':
      return 'Payment provider customer created'
    case 'UserAccountOperation':
      return 'Account created or updated'
  }
}

function TimelineItem({ timelineItem }: TimelineItemProps) {
  return (
    <>
      <Text variant="body2">{formatDateTimeSeconds(timelineItem.timestamp)}</Text>
      <Text variant="body2">{getItemLabel(timelineItem)}</Text>
      <TimelineDetailsGroup>
        {timelineItem.type === 'InvoiceEmission' ? (
          <InvoiceLifecycleEventDetail invoiceId={timelineItem.invoice.invoiceId} />
        ) : (
          <></>
        )}
        {timelineItem.type === 'PaymentUpdate' ? (
          <PaymentUpdateDetail invoiceNumber={timelineItem.invoiceNumber} paymentUpdate={timelineItem.event} />
        ) : (
          <></>
        )}
        {timelineItem.type === 'PaymentProviderCustomerCreation' ? (
          <PaymentProviderCustomerCreationDetail paymentProviderCustomerCreation={timelineItem.event} />
        ) : (
          <></>
        )}
        {timelineItem.type === 'PaymentMethodCreation' ? (
          <Text variant="body2">{getPaymentMethodDisplayName(timelineItem.paymentMethodDescription)}</Text>
        ) : (
          <></>
        )}
      </TimelineDetailsGroup>
    </>
  )
}

type InvoiceLifecycleEventDetailProps = {
  invoiceId: string
}

function InvoiceLifecycleEventDetail({ invoiceId }: InvoiceLifecycleEventDetailProps) {
  const { permissions } = usePermissions()

  return permissions.includes('invoices.read') ? (
    <ButtonLink size="small" variant="secondary" to={getAdminInvoiceDownloadLink(invoiceId)} target="_blank">
      Invoice
    </ButtonLink>
  ) : null
}

type PaymentUpdateDetailProps = {
  paymentUpdate: SupportedPaymentUpdate
  invoiceNumber: string
}

function PaymentUpdateDetail({ paymentUpdate, invoiceNumber }: PaymentUpdateDetailProps) {
  const { type: userType, permissions } = usePermissions()
  const stripePaymentIntentId =
    'stripePaymentIntentId' in paymentUpdate ? paymentUpdate.stripePaymentIntentId : undefined
  const invoiceId = paymentUpdate.invoiceId
  return (
    <>
      {invoiceNumber}
      &nbsp;
      {paymentUpdate.type === 'updated' ? paymentUpdate.status : paymentUpdate.manuallySetInvoiceStatus + ' (manuel)'}
      &nbsp;
      {paymentUpdate.type === 'updated' && paymentUpdate.status === 'failed' && 'declineCode' in paymentUpdate
        ? paymentUpdate.declineCode
        : null}
      {userType === 'platform' &&
      stripePaymentIntentId &&
      permissions.includes('stripe.read') &&
      paymentUpdate.type === 'updated' ? (
        <>
          {paymentUpdate.provider === 'gocardless' ? (
            <ButtonLink
              size="small"
              variant="secondary"
              to={getGocardlessPaymentIntentUrl(stripePaymentIntentId)}
              target="_blank"
            >
              GoCardless
            </ButtonLink>
          ) : (
            <ButtonLink
              size="small"
              variant="secondary"
              to={getStripePaymentIntentUrl(stripePaymentIntentId)}
              target="_blank"
            >
              Stripe
            </ButtonLink>
          )}
        </>
      ) : null}
      {permissions.includes('invoices.read') ? (
        <>
          <ButtonLink size="small" variant="secondary" to={getAdminInvoiceDownloadLink(invoiceId)} target="_blank">
            Invoice
          </ButtonLink>
        </>
      ) : null}
      {((paymentUpdate.type === 'updated' && paymentUpdate.status === 'paid') ||
        (paymentUpdate.type === 'manual-payment-registration' && paymentUpdate.manuallySetInvoiceStatus === 'paid')) &&
      permissions.includes('invoices.read') ? (
        <>
          <ButtonLink size="small" variant="secondary" to={getAdminReceiptDownloadLink(invoiceId)} target="_blank">
            Receipt
          </ButtonLink>
        </>
      ) : null}
    </>
  )
}

type PaymentProviderCustomerCreationDetailProps = {
  paymentProviderCustomerCreation: StripeCustomerCreation
}

function PaymentProviderCustomerCreationDetail({
  paymentProviderCustomerCreation,
}: PaymentProviderCustomerCreationDetailProps) {
  const { type: userType, permissions } = usePermissions()
  return userType === 'platform' && permissions.includes('stripe.read') ? (
    <>
      {paymentProviderCustomerCreation.provider === 'gocardless' ? (
        <ButtonLink
          size="small"
          variant="secondary"
          to={getGocardlessCustomerUrl(paymentProviderCustomerCreation.stripeCustomerId)}
          target="_blank"
        >
          GoCardless
        </ButtonLink>
      ) : (
        <ButtonLink
          size="small"
          variant="secondary"
          to={getStripeCustomerUrl(paymentProviderCustomerCreation.stripeCustomerId)}
          target="_blank"
        >
          Stripe
        </ButtonLink>
      )}
    </>
  ) : null
}

const TimelineDetailsGroup = styled(FlexRow)`
  min-width: 250px;
  width: 100%;
  gap: ${spacing[40]};
  justify-content: flex-end;

  ${mobileMediaQuery} {
    flex-wrap: wrap;
    padding-top: ${spacing[30]};
    padding-bottom: ${spacing[30]};
  }
`
